import React, { Fragment } from 'react';
import { Spoiler, Table } from '@mantine/core';
import { List, ThemeIcon } from '@mantine/core';
import { CircleCheck } from 'tabler-icons-react';
import Layout from '../../components/Layout';
import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';
import Tabs from '../../components/Tabs';
import adegoke from '../../assets/images/departments/medicine/adegoke.png';
import adelowo from '../../assets/images/departments/medicine/adelowo.png';
import adewale from '../../assets/images/departments/medicine/adewale.png';
import adewunmi from '../../assets/images/departments/medicine/adewunmi.png';
import team1 from '../../assets/images/team-1-1.jpg';
import agabi from '../../assets/images/departments/medicine/agabi.png';
import ajuluchukwu from '../../assets/images/departments/medicine/ajuluchukwu.png';
import akase from '../../assets/images/departments/medicine/akase.png';
import akinkugbe from '../../assets/images/departments/medicine/akinkugbe.png';
import akinsanya from '../../assets/images/departments/medicine/akinsanya.png';
import amadi from '../../assets/images/departments/medicine/amadi.png';
import amira from '../../assets/images/departments/medicine/amira.png';
import ayanlowo from '../../assets/images/departments/medicine/ayanlowo.png';
import bello from '../../assets/images/departments/medicine/bello.png';
import braimoh from '../../assets/images/departments/medicine/braimoh.png';
import dania from '../../assets/images/departments/medicine/dania.png';
import fasanmade from '../../assets/images/departments/medicine/fasanmade.png';
import kalejaiye from '../../assets/images/departments/medicine/kalejaiye.png';
import korede from '../../assets/images/departments/medicine/korede.png';
import kushimo from '../../assets/images/departments/medicine/kushimo.png';
import mbakwem from '../../assets/images/departments/medicine/mbakwem.png';
import njideka from '../../assets/images/departments/medicine/njideka.png';
import odeghe from '../../assets/images/departments/medicine/odeghe.png';
import odeniyi from '../../assets/images/departments/medicine/odeniyi.png';
import ojini from '../../assets/images/departments/medicine/ojini.png';
import ojo from '../../assets/images/departments/medicine/ojo.png';
import olayemi from '../../assets/images/departments/medicine/olayemi.png';
import olopade from '../../assets/images/departments/medicine/olopade.png';
import otrofanowei from '../../assets/images/departments/medicine/otrofanowei.png';
import oyeleke from '../../assets/images/departments/medicine/oyeleke.png';
import ozoh from '../../assets/images/departments/medicine/ozoh.png';

const Medicine = () => {
  const cardioServices = [
    { name: 'Cardiology Out-patient Clinics (General, Heart Failure)' },
    { name: 'Cardiology In-patient services' },
    { name: 'Cardiology Emergency Services ' },
    { name: 'Cardiology Inter-disciplinary Consultations' },
    { name: 'Cardiology Intensive Care services' },
    { name: 'Echocardiography ' },
    { name: 'Electrocardiography' },
    { name: 'Holter monitoring' },
    { name: 'Ambulatory blood pressure monitoring (ABPM)' },
    { name: 'Stress electrocardiography ' },
    
    { name: 'Pre-surgery cardiovascular assessments ' },
    { name: 'Ultrasound-guided pericardiocentesis' },
    { name: 'Cardiology Specialty Procedures ' },
    { name: 'Basic Life Support and Advanced Cardiac Life Support ' },
  ];

  const dermatologyServices = [
    {
      name:
        'Dermatology & GUM Out-patient Clinics (General, Genitourinary/STD/HIV/Procedures)',
    },
    { name: 'Dermatology & GUM In-patient services' },
    { name: 'Dermatology & GUM Emergency Services ' },
    { name: 'Dermatology & GUM Inter-disciplinary Consultations' },
    {
      name:
        'Dermatology Procedures (Skin biopsies (elliptical, punch, shave biopsies); ',
    },
    { name: 'Dermatohistopathology and immunohistochemistry; ' },
    {
      name:
        'Lumpectomy (e.g., onchocercal nodule & various subcutaneous nodules)',
    },
    {
      name: 'Dermal Curettage Cryotherapy',
    },
    {
      name: 'Extirpation and povidone-iodine application',
    },
    { name: 'Pairing and Podophyllin Application' },
    { name: 'Intralesional drug injections (triamcinolone and % Fluorouracil' },
    { name: 'Chemical Peels' },
    { name: 'Patch Test' },
    { name: 'Dermoscopy' },
    {
      name: 'Radiotherapy (Department of Radiotherapy and Radiation Biology);',
    },
    { name: 'Intraarticular injection of steroids and hyaluronidase' },
    {
      name:
        'Intravenous chemotherapy and biologics for connective tissue disorders and psoriasis',
    },
    { name: 'Skin and Nail scrapings and microscopy' },
    { name: 'Clinical Photography' },
    { name: 'Skin swab & biopsies for microscopy, culture and sensitivity' },
    { name: 'Skin scrapings' },
    {
      name:
        'Hair pull and nail clippings for microscopy and culture (mycology) Skin slit and smear)',
    },
  ];

  const endocrinologyServices = [
    { name: 'Diabetes Out-patient Clinic' },
    { name: 'Thyroid Disorders Outpatient Clinic' },
    { name: 'General Endocrinology and Metabolism Out-patient Clinic' },
    { name: 'EDM In-patient services' },
    { name: 'EDM Emergency Services ' },
    { name: 'EDM Inter-disciplinary Consultations' },
    { name: 'EDM Procedures (Oral glucose tolerance test' },
    { name: 'Dexamethasone Suppression Test; Synacthen Test' },
    {
      name:
        'Homeostasis Model Assessment (HOMA) for beta-cell function and insulin resistance',
    },
    { name: 'Quantitative and Semi-quantitative Vibrametry' },
    { name: 'Mini-Doppler Ultrasound Scan (Arterial and Venous)' },
    { name: 'Ankle-brachial index measurement' },
    { name: 'Thyroid scan' },
    { name: 'Thyroid cyst aspiration' },
    { name: 'Biothesiometry' },
  ];

  const gastroenterologyServices = [
    { name: 'Gastroenterology & Hepatology (Hepatitis) Out-patient Clinics ' },
    { name: 'Gastroenterology & Hepatology In-patient services' },
    { name: 'Gastroenterology & Hepatology Emergency Services ' },
    { name: 'Gastroenterology & Hepatology Inter-disciplinary Consultations' },
    {
      name:
        'Gastroenterology & Hepatology Procedures (Diagnostic gastroscopy and biopsy; Therapeutic gastroscopy with variceal band ligation; Oesophagal balloon dilatation; Diagnostic colonoscopy and biopsy; Cold biopsy of colonic lesions; Percutaneous liver biopsy; Ultrasound-guided liver biopsy; Fine needle liver aspiration (FNA); Liver abscess drainage; Peritoneal biopsy; Upper abdominal ultrasound; Paracentesis abdominis)',
    },
  ];

  const haematologyServices = [
    {
      name:
        'Haematology & Oncology Out-patient Clinic (General; Sickle Cell Disorders)',
    },
    { name: 'Haematology & Oncology In-patient services' },
    { name: 'Haematology & Oncology Emergency Services ' },
    { name: 'Haematology & Oncology Inter-disciplinary Consultations' },
    { name: 'Pre-conception and pre-implantation counselling ' },
    { name: 'Pre-bone marrow transplantation screening and counselling' },
    { name: 'Bone Marrow Transplantation Services (new) ' },
    {
      name:
        'Haematology & Oncology Procedures (Bone marrow aspiration and analysis; Bone marrow (Trephine) biopsy and analysis; Peripheral blood film analysis; Coagulation screen; Full blood count analysis; Haemoglobin genotype analysis; Sickling test; Leukaemia panels; Exchange blood transfusions; Blood and blood product transfusions; Apharesis)',
    },
  ];

  const idServices = [
    { name: 'Infectious Diseases Out-patient Clinic' },
    { name: 'Post-COVID Clinic ' },
    { name: 'Infectious Diseases In-patient services' },
    { name: 'COVID Isolation and Treatment Services' },
    { name: 'Infectious Diseases Emergency Services ' },
    { name: 'Infectious Diseases Inter-disciplinary Consultations' },
    { name: 'Infectious Diseases Control and Surveillance ' },
    {
      name:
        'Infectious Diseases Procedures (Antimicrobial stewardship & Infection control; Out-patient parenteral antimicrobial therapy; Congenital & Acquired immunodeficiency evaluations and treatment)',
    },
  ];

  const neurologyServices = [
    { name: 'General Neurology Out-patient Clinic ' },
    { name: 'Headache Disorders Out-patient Clinic' },
    { name: 'Parkinson Disease and Movement Disorders Out-patient Clinic ' },
    { name: 'General Neurology In-patient services' },
    { name: 'Neuro-intensive Care services ' },
    { name: 'Neurological Emergency Services ' },
    { name: 'Acute Stroke Unit' },
    { name: 'Neurology Inter-disciplinary Consultations' },
    { name: 'Sleep Disorders Consultation ' },
    { name: 'Electrodiagnostic Consultation' },
    {
      name:
        'Neurology Procedures (Digital Electroencephalography; Electromyography and Nerve Conduction; Polysomnography; Neuroimaging; Lumbar puncture; Botulinum toxin chemodenervation (including EMG-guided); Therapeutic CSF drainage)',
    },
  ];

  const renalServices = [
    { name: 'Renal Out-patient Clinic' },
    { name: 'Renal In-patient services' },
    { name: 'Renal Emergency Services ' },
    { name: 'Renal Inter-disciplinary Consultations' },
    { name: 'Pre-transplantation Screening and Counselling' },
    { name: 'Post-transplantation Clinic and Support Services  ' },
    {
      name:
        'Kidney Institute Dialysis Services (Haemodialysis, Peritoneal Dialysis)',
    },
    {
      name:
        'Renal Procedures (Haemodialysis; Peritoneal dialysis; Kidney transplantation; Continuous renal replacement therapy (CRRT) in ICU; Renal biopsy; Jugular vein catheterization)',
    },
  ];

  const pulmonologyServices = [
    { name: 'Pulmonology Out-patient Clinic (General, Asthma and COPD)' },
    { name: 'Pulmonology In-patient services' },
    { name: 'Pulmonology Emergency Services ' },
    { name: 'Pulmonology Inter-disciplinary Consultations' },
    { name: 'Spirometry Laboratory ' },
    {
      name:
        'Pulmonology Procedures (Pre and post-bronchodilator spirometry; Exercise challenge tests; 6 minutes walk test; Induced sputum; Pleural biopsy; Thoracocentesis; Ultrasound-guided biopsy; Interpretation of sleep tests for obstructive sleep apnea screening; Peak flow measurement)',
    },
  ];

  const rheumatologyServices = [
    { name: 'Rheumatology Out-patient Clinic' },
    { name: 'Rheumatology In-patient services' },
    { name: 'Rheumatology Emergency Services ' },
    { name: 'Rheumatology Inter-disciplinary Consultations' },
    { name: 'Lupus and related disorders inter-disciplinary consultations ' },
    {
      name:
        'Rheumatology procedures (Intra-articular viscosupplementation; Intravenous chemotherapy and immunomodulatory therapies; Soft tissue intralesional injections)',
    },
  ];

  const subs = [
    {
      name: 'Cardiology',
      consultants: 'Prof. David A. Oke',
    },
    {
      name: 'Cardiology',
      consultants: 'Prof. Jayne N. A. Ajuluchukwu',
    },
    {
      name: 'Cardiology',
      consultants: 'Prof. Amam C. Mbakwem',
    },
    {
      name: 'Cardiology',
      consultants: 'Dr. Adegoke K. Ale',
    },
    {
      name: 'Cardiology',
      consultants: 'Dr. Casmir E. Amadi',
    },
    {
      name: 'Cardiology',
      consultants: 'Dr. Akinsanya D. Olusegun-Joseph',
    },
    {
      name: 'Cardiology',
      consultants: 'Dr. Oluseyi Adegoke',
    },
    {
      name: 'Cardiology',
      consultants: 'Dr. Oyewole Kushimo',
    },
    {
      name: 'Dermatology & Genitourinary Medicine ',
      consultants: 'Dr. Olusola O. Ayanlowo',
    },
    {
      name: 'Dermatology & Genitourinary Medicine ',
      consultants: 'Dr. Ayesha O. Akinkugbe',
    },
    {
      name: 'Dermatology & Genitourinary Medicine ',
      consultants: 'Dr. Erere Otrofanowei',
    },
    {
      name: 'Emergency Medicine',
      consultants: 'Prof. Sunday O. Olayemi',
    },
    {
      name: 'Emergency Medicine',
      consultants: 'Dr. Adebowale J. Adewunmi',
    },
    {
      name: 'Endocrinology, Diabetes & Metabolism',
      consultants: 'Prof. Olufemi A. Fasanmade',
    },
    {
      name: 'Endocrinology, Diabetes & Metabolism',
      consultants: 'Dr. Ifedayo  A. Odeniyi',
    },
    {
      name: 'Endocrinology, Diabetes & Metabolism',
      consultants: 'Dr. Oluwarotimi B. Olopade ',
    },
    {
      name: 'Gastroenterology & Hepatology',
      consultants: 'Prof. Olufunmi Lesi',
    },
    {
      name: 'Gastroenterology & Hepatology',
      consultants: 'Dr. Emuobor Odeghe',
    },
    {
      name: 'Gastroenterology & Hepatology',
      consultants: 'Dr. Ganiat K. Oyeleke',
    },
    {
      name: 'Haematology & Oncology',
      consultants: 'Dr. Olufunto Kalejaiye',
    },
    {
      name: 'Haematology & Oncology',
      consultants: 'Dr. Yusuf A. Adelabu (CMUL)',
    },
    {
      name: 'Infectious Diseases',
      consultants: 'Dr. Ephraim I. Akase',
    },
    {
      name: 'Renal Medicine',
      consultants: 'Dr. Monica O. Mabayoje',
    },
    {
      name: 'Renal Medicine',
      consultants: 'Prof. Christiana O. Amira',
    },
    {
      name: 'Renal Medicine',
      consultants: 'Dr. Rotimi W. Braimoh',
    },
    {
      name: 'Renal Medicine',
      consultants: 'Dr. Babawale T. Bello',
    },
    {
      name: 'Neurology',
      consultants: 'Prof. Francis I. Ojini',
    },
    {
      name: 'Neurology',
      consultants: 'Prof. Njideka U. Okubadejo',
    },
    {
      name: 'Neurology',
      consultants: 'Dr. Oluwadamilola O. Ojo',
    },
    {
      name: 'Neurology',
      consultants: 'Dr. Osigwe P. Agabi',
    },
    {
      name: 'Pulmonology ',
      consultants: 'Dr. Obianuju B. Ozoh',
    },
    {
      name: 'Pulmonology ',
      consultants: 'Dr. Michelle G. Dania',
    },
    {
      name: 'Rheumatology',
      consultants: 'Prof.  Olufemi O. Adelowo',
    },
    {
      name: 'Rheumatology',
      consultants: 'Dr. Uyiekpen Ima-Edomwonyi',
    },
  ];

  const subRows = subs.map((element) => (
    <Fragment>
      <tr key={element.name}>
        <td>{element.name}</td>
        <td>{element.consultants}</td>
      </tr>
    </Fragment>
  ));

  const clinicSchedule = [
    { clinic: 'Respiratory ', day: 'Monday', time: '9:00 am' },
    { clinic: 'Renal Medicine (Team B)', day: 'Monday', time: '9:00 am' },
    { clinic: 'Sickle Cell Disorders ', day: 'Monday', time: '9:00 am' },
    {
      clinic: 'Dermatology/Genitourinary Medicine (Yaba LUTH Annex) ',
      day: 'Monday',
      time: '9:00 am',
    },
    { clinic: 'Headache Disorders', day: 'Monday', time: '1:00 pm' },
    {
      clinic: 'Parkinson Disease & Movement Disorders ',
      day: 'Monday',
      time: '1:00 pm',
    },
    {
      clinic: 'Gastroenterology (Hepatology Clinic)',
      day: 'Monday',
      time: '2:00pm',
    },
    {
      clinic: 'Endocrinology, Diabetes & Metabolism New Patient Clinic ',
      day: 'Monday',
      time: '2:00pm',
    },
    {
      clinic: '- STD and HIV (Genitourinary Medicine)',
      day: 'Tuesday',
      time: '9:00 am',
    },
    {
      clinic: '- Dermatology Procedures Clinic ',
      day: 'Tuesday',
      time: '9:00 am',
    },
    { clinic: 'Diabetes Clinic ', day: 'Tuesday', time: '9:00 am' },
    { clinic: 'Cardiology (Team A)', day: 'Tuesday', time: '9:00 am' },
    {
      clinic: 'Respiratory (Asthma and COPD Clinic) ',
      day: 'Wednesday',
      time: '1:00 pm',
    },
    {
      clinic: 'Infectious Diseases / Post-COVID Clinic ',
      day: 'Wednesday',
      time: '1:00 pm',
    },
    { clinic: 'Neurology (General)', day: 'Thursday', time: '8:00 am' },
    { clinic: 'Gastroenterology', day: 'Thursday', time: '9:00 am' },
    { clinic: 'Hematology & Oncology', day: 'Thursday', time: '9:00 am' },
    {
      clinic: 'Endocrinology & Metabolism (Thyroid and Others)',
      day: 'Thursday',
      time: '2:00 pm',
    },
    { clinic: 'Cardiology (Team B)', day: 'Friday', time: '9:00 am' },
    { clinic: 'Renal Medicine (Team A)', day: 'Friday', time: '9:00 am' },
    { clinic: 'Dermatology', day: 'Friday', time: '9:00 am' },
  ];

  const clinicRows = clinicSchedule.map((element) => (
    <Fragment>
      <tr key={element.clinic}>
        <td>{element.day}</td>
        <td>{element.time}</td>
        <td>{element.clinic}</td>
      </tr>
    </Fragment>
  ));
  return (
    <Layout pageTitle='Department of Medicine | LUTH'>
      <NavOne />
      <PageHeader title='Department of Medicine' />
      <section className='course-details'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='course-details__content'>
                <div className='course-details__top'>
                  <div className='course-details__top-left'>
                    <h2 className='course-details__title'>
                      Department of Medicine
                    </h2>
                  </div>
                </div>
                <Tabs>
                  <div label='Overview'>
                    <Spoiler
                      maxHeight={300}
                      hideLabel='Hide'
                      showLabel='Show More'
                    >
                      <p className='course-details__tab-text'>
                        The Department of Medicine is one of the foundation
                        Departments of the Lagos University Teaching Hospital
                        established at the inception in 1962, and is currently
                        one of the largest and most prominent Departments in the
                        LUTH. The purpose of the Department is to provide
                        world-class service to the clients of the hospital
                        through its various specialties, train and mentor
                        undergraduate and postgraduate students in Internal
                        Medicine and its specialties, and conduct research using
                        best practices to foster the understanding of the
                        causes, prevention, and treatment of disorders in the
                        field. Our goal is to align with acceptable and
                        evidence-based global standards relevant to our national
                        requirements and international paradigms. Our vision is
                        to consistently offer qualitative, world-class service
                        and education and conduct research across the
                        specialties of Internal Medicine, using technologically
                        appropriate, contextually relevant, yet ethically sound
                        and responsive methods and interventions, provided by
                        dedicated and committed staff. In achieving this, the
                        Department consistently engages in and invests in
                        capacity development, providing a conducive atmosphere
                        where staff and students thrive, and promoting academic
                        freedom that encourages staff to explore their
                        potentials in the ever-changing landscape of practice,
                        research and education in Internal Medicine. The core
                        values of the Department are captured in its dictum,
                        STRIDE – Service- and Student-centricity, Teamwork,
                        Reliability, Integrity, Diligence/Dedication, Empathy,
                        hence the appellation – the ‘STRIDEing’ Department.{' '}
                        <br />
                        <br />
                        Over the years, the Department has expanded its spectrum
                        of internal medicine specialties to include an
                        outstanding and enviable bouquet, possibly with the
                        largest representation of the retinue of medical
                        specialties in the West African subregion at a single
                        institution. The specialties are as follows: Cardiology,
                        Dermatology & Genitourinary Medicine, Endocrinology,
                        Diabetes & Metabolism, Emergency Medicine,
                        Gastroenterology & Hepatology, Hematology & Oncology,
                        Infectious Diseases, Neurology, Pulmonology, Renal
                        Medicine, and Rheumatology. There is a short-term plan
                        to commence specific Geriatrics training and services
                        under the auspices of the department. The specialties
                        are manned by academic researcher-clinicians of national
                        and international repute, recognized as regional leaders
                        in clinical practice, medical education and research.
                        <h2
                          className='blog-one__title'
                          style={{ marginTop: 30 }}
                        >
                          Our Vision, Mission, And Core Values
                        </h2>
                        <strong>VISION</strong> <br />
                        To be the foremost Internal Medicine department in the
                        country, providing patient-centred, evidence-based
                        medical care of the highest quality and training
                        specialists with proficiency in knowledge and skills for
                        disease management, with versatility in application, and
                        demonstrating professionalism and best ethical practice.
                        <br />
                        <br />
                        <strong>MISSION</strong>
                        <br />
                        The mission of the department aligns with that of the
                        Lagos University Teaching Hospital to be a cutting-edge
                        department of Internal Medicine, impacting the community
                        through its tripartite roles of training, service, and
                        research/innovation. Our mission is thus to: <br />•
                        provide patient care by applying globally-accepted,
                        nationally-adapted evidence-based standards of best
                        clinical practice <br />• train physician specialists in
                        Internal Medicine with requisite expertise and
                        competencies to address current and emerging national
                        and global health issues in the specialty <br />•
                        contribute significantly to the development of health
                        policies and practices that address the health issues of
                        the nation through comprehensive, contextually relevant
                        and ethical research
                        <br />
                        <br />
                        <strong>CORE VALUES</strong>
                        <br />
                        The core values of the Department are captured by tour
                        self-assigned mantra captured in the acronym ‘STRIDE’,
                        which describes our shared and deeply valued principles.
                        Our departmental policies and processes are driven by
                        these ideals and are designed to inculcate the same
                        morals in our trainees and mentees. <br />
                        <strong>S</strong> - Service- and Student-centricity{' '}
                        <br />
                        <strong>T</strong> - Teamwork <br />
                        <strong>R</strong> - Reliability <br />
                        <strong>I</strong> - Integrity <br />
                        <strong>D</strong> - Dedication and Diligence <br />
                        <strong>E</strong> - Empathy <br />
                        <br />
                        <strong>CONTACT US:</strong> +2348023083346; LUTH
                        website/Medicine
                      </p>
                    </Spoiler>
                  </div>
                  <div label='Programs And Training'>
                    <h2 className='blog-one__title' style={{ marginTop: 2 }}>
                      Undergraduate programs
                    </h2>
                    <p className='course-details__tab-text'>
                      • Bachelor of Medicine, Bachelor of Surgery (MBBS) program
                      (400 – 600 level clinical rotations at the Lagos
                      University Teaching Hospital) <br />• Bachelor of Dental
                      Sciences (BDS) (400 level BDS Clinical rotation in
                      Internal Medicine) <br />• Sub-internships for MBBS 600
                      level CMUL SPICCES curriculum <br />• Undergraduate
                      electives (Internal and External students)
                      <h2 className='blog-one__title' style={{ marginTop: 25 }}>
                        Postgraduate programs (Internal Medicine, Subspecialties
                        and Allied Specialties Training)
                      </h2>
                      In collaboration with the Lagos University Teaching
                      Hospital, the National Postgraduate Medical College of
                      Nigeria, and the West African Postgraduate medical
                      College, the Department is a leading accredited site for
                      training postgraduate physicians in General Internal
                      Medicine and Medical subspecialties in the Faculty of
                      Internal Medicine fellowship programs of both colleges
                      (FMCP and FWACP). Furthermore, mandated training for
                      allied specialties including Psychiatry, Neurosurgery,
                      Ophthalmology, Radiodiagnosis, Surgical Specialties,
                      Anesthesia, Family Medicine, Emergency Medicine,
                      Geriatrics, and others are undertaken in the department.
                      The department receives resident doctors from across all
                      regions of the country including as primary site for
                      training and supernumerary trainees from sites without
                      accreditation, due to its extensive accreditation in all
                      specialties. The department is also host to the MD program
                      of the National Postgraduate Medical College of Nigeria.
                      <h2 className='blog-one__title' style={{ marginTop: 25 }}>
                        Certificate programs and Short Workshops
                      </h2>
                      The department hosts several innovative programs,
                      typically in conjunction with other departments,
                      institutional societies, professional organizations or
                      industry, and across the specialties. This includes the
                      current national Electroencephalography (EEG)
                      Technologists course, now in its’ second year, in
                      conjunction with Medilag Consult and the Nigerian Society
                      of Neurological Sciences that selected the department
                      specifically to host the program due to the expertise
                      available to sustain the program in the institution.
                      <h2 className='blog-one__title' style={{ marginTop: 25 }}>
                        Medical Education and Capacity Building program
                      </h2>
                      The department encourages staff to pursue professional
                      development programs identified as potentially
                      contributing to their competencies as service providers
                      and knowledge creators (through research and teaching).
                      There is an existing program for training in medical
                      education modalities hosted by the department annually (in
                      January each year) with content covering teaching,
                      evaluation/assessment methods in medical education. The
                      fallout is that over the years, the quality and standard
                      of assessments has been upgraded to global standards.
                      <h2 className='blog-one__title' style={{ marginTop: 25 }}>
                        Future directions
                      </h2>
                      The department is in the process of spear-heading the
                      development of the Geriatrics Unit which will be a
                      multi-disciplinary service domiciled in the department,
                      with plans in process for expanding to a Regional
                      Geriatrics Centre funded by the Federal government. In
                      addition, each of the specialties has continued to explore
                      avenues for expanding its specialization into relevant
                      aspects. For instance, the Hematology & Oncology Unit is
                      core to the Bone Marrow Transplant service, the Renal
                      Medicine Unit is spearheading the new Kidney Institute
                      services and research, and the Neurology Unit is exploring
                      expanding the Acute Stroke Unit to include interventional
                      therapies for acute stroke care.
                      <h2 className='blog-one__title' style={{ marginTop: 25 }}>
                        Research Output (Publications and Research Grants)
                      </h2>
                      Departmental academic staff are actively engaged in
                      research, innovations and publications (including journal
                      articles, textbooks, and public education material) based
                      on the areas of expertise. The Department has consistently
                      held the enviable position of being one of the most highly
                      productive in the University in terms of research grant
                      funding received, and the horizon continues to expand.
                      Staff participate in workshops on responsible conduct of
                      research, grantsmanship, and mentorship, and significant
                      progress has been made in sustaining the culture through
                      training of early career academicians through sustained
                      participation in institutional initiatives such as the
                      BRAINS mentored research grants. The list of current
                      publications of academic staff are available on Google
                      Scholar ® and PubMed ® in addition to other online
                      databases, and demonstrates the active engagement in
                      impactful and productive research.
                      <h2 className='blog-one__title' style={{ marginTop: 25 }}>
                        Faculty Engagements (Workshops, Speakerships,
                        Administration and Advocacy)
                      </h2>
                      Faculty are actively engaged in providing expertise as
                      national and international speakers, faculty and
                      facilitators in workshops, administrators and committee
                      and professional organization leadership and memberships
                      and advocates for public health policy, health education
                      and health literacy.
                      <h2 className='blog-one__title' style={{ marginTop: 25 }}>
                        Clinical Diagnostic and Therapeutic Services
                      </h2>
                      The department is the backbone of clinical services to the
                      Lagos University Teaching Hospital. The philosophy of the
                      department is to provide outstanding patient-centered care
                      that is of high quality, compassionate, cost-conscious,
                      responsive and integrative of the existing health systems
                      infrastructure. In recent times, the department has
                      adapted to the emerging paradigm of incorporating
                      e-health/telehealth The hospital relies on the retinue of
                      honorary consultant physician specialists to serve the
                      needs of the over 800-bed multispecialty facility
                      including in-patient, out-patient, emergency and
                      laboratory/diagnostic services (including the
                      Cardiovascular laboratory, Respiratory Laboratory,
                      Neurophysiology (electroencephalography, nerve conduction
                      and electromyography), and therapeutic services (e.g.
                      renal replacement therapy) amongst others, providing
                      support to other surgical and non-surgical services. The
                      department is at the helm of coordination of infectious
                      disease clinical services through its Infectious Diseases
                      Unit, including championing the national and state
                      response to the COVID-19 pandemic, providing the key
                      leadership of the institution’s COVID-19 response team and
                      the Lagos State response team. This is a continuation of
                      the recurring role the unit has played in providing
                      expertise to guide response to national disease outbreaks
                      such as Lassa fever, and provide guidance to the Nigeria
                      Centre for Disease Control (NCDC).
                    </p>
                  </div>
                  <div label='Services'>
                    <Spoiler
                      maxHeight={600}
                      hideLabel='Hide'
                      showLabel='Show More'
                    >
                      <Fragment>                                                                        
                        <h2
                          className='blog-one__title'
                          style={{ marginTop: 50 }}
                        >
                          Services Available In The Deparment
                        </h2>
                        <strong>CARDIOLOGY</strong>
                        <List
                          spacing='xs'
                          size='sm'
                          color='red'
                          center
                          icon={
                            <ThemeIcon color='teal' size={24} radius='xl'>
                              <CircleCheck size={16} />
                            </ThemeIcon>
                          }
                        >
                          {cardioServices.map((data) => (
                            <List.Item key={data.name}>{data.name}</List.Item>
                          ))}
                        </List>
                        <br />
                        <strong>DERMATOLOGY & GENITOURINARY MEDICINE</strong>
                        <List
                          spacing='xs'
                          size='sm'
                          color='red'
                          center
                          icon={
                            <ThemeIcon color='teal' size={24} radius='xl'>
                              <CircleCheck size={16} />
                            </ThemeIcon>
                          }
                        >
                          {dermatologyServices.map((data) => (
                            <List.Item key={data.name}>{data.name}</List.Item>
                          ))}
                        </List>
                        <br />
                        <strong>
                          ENDOCRINOLOGY, DIABETES & METABOLISM (EDM)
                        </strong>
                        <List
                          spacing='xs'
                          size='sm'
                          color='red'
                          center
                          icon={
                            <ThemeIcon color='teal' size={24} radius='xl'>
                              <CircleCheck size={16} />
                            </ThemeIcon>
                          }
                        >
                          {endocrinologyServices.map((data) => (
                            <List.Item key={data.name}>{data.name}</List.Item>
                          ))}
                        </List>
                        <br />
                        <strong>GASTROENTEROLOGY & HEPATOLOGY</strong>
                        <List
                          spacing='xs'
                          size='sm'
                          color='red'
                          center
                          icon={
                            <ThemeIcon color='teal' size={24} radius='xl'>
                              <CircleCheck size={16} />
                            </ThemeIcon>
                          }
                        >
                          {gastroenterologyServices.map((data) => (
                            <List.Item key={data.name}>{data.name}</List.Item>
                          ))}
                        </List>
                        <br />
                        <strong>HAEMATOLOGY & ONCOLOGY</strong>
                        <List
                          spacing='xs'
                          size='sm'
                          color='red'
                          center
                          icon={
                            <ThemeIcon color='teal' size={24} radius='xl'>
                              <CircleCheck size={16} />
                            </ThemeIcon>
                          }
                        >
                          {haematologyServices.map((data) => (
                            <List.Item key={data.name}>{data.name}</List.Item>
                          ))}
                        </List>
                        <br />
                        <strong>INFECTIOUS DISEASES</strong>
                        <List
                          spacing='xs'
                          size='sm'
                          color='red'
                          center
                          icon={
                            <ThemeIcon color='teal' size={24} radius='xl'>
                              <CircleCheck size={16} />
                            </ThemeIcon>
                          }
                        >
                          {idServices.map((data) => (
                            <List.Item key={data.name}>{data.name}</List.Item>
                          ))}
                        </List>
                        <br />
                        <strong>NEUROLOGY</strong>
                        <List
                          spacing='xs'
                          size='sm'
                          color='red'
                          center
                          icon={
                            <ThemeIcon color='teal' size={24} radius='xl'>
                              <CircleCheck size={16} />
                            </ThemeIcon>
                          }
                        >
                          {neurologyServices.map((data) => (
                            <List.Item key={data.name}>{data.name}</List.Item>
                          ))}
                        </List>
                        <br />
                        <strong>RENAL MEDICINE</strong>
                        <List
                          spacing='xs'
                          size='sm'
                          color='red'
                          center
                          icon={
                            <ThemeIcon color='teal' size={24} radius='xl'>
                              <CircleCheck size={16} />
                            </ThemeIcon>
                          }
                        >
                          {renalServices.map((data) => (
                            <List.Item key={data.name}>{data.name}</List.Item>
                          ))}
                        </List>
                        <br />
                        <strong>PULMONOLOGY</strong>
                        <List
                          spacing='xs'
                          size='sm'
                          center
                          icon={
                            <ThemeIcon color='teal' size={24} radius='xl'>
                              <CircleCheck size={16} />
                            </ThemeIcon>
                          }
                        >
                          {pulmonologyServices.map((data) => (
                            <List.Item key={data.name}>{data.name}</List.Item>
                          ))}
                        </List>
                        <br />
                        <strong>RHEUMATOLOGY</strong>
                        <List
                          spacing='xs'
                          size='sm'
                          center
                          icon={
                            <ThemeIcon color='teal' size={24} radius='xl'>
                              <CircleCheck size={16} />
                            </ThemeIcon>
                          }
                        >
                          {rheumatologyServices.map((data) => (
                            <List.Item key={data.name}>{data.name}</List.Item>
                          ))}
                        </List>
                        <h2
                          className='blog-one__title'
                          style={{ marginTop: 50 }}
                        >
                          List of Clinics and Clinic Schedule
                        </h2>
                        <Table fontSize='md' striped>
                          <thead>
                            <tr>
                              <th>Day</th>
                              <th>Time</th>
                              <th>Clinic</th>
                            </tr>
                          </thead>
                          <tbody>{clinicRows}</tbody>
                        </Table>
                      </Fragment>
                    </Spoiler>
                  </div>
                  <div label='Staff'>
                    <React.Fragment>
                      <h2 className='blog-one__title'>Staff Details</h2>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={njideka} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Prof. Njideka Ulunma OKUBADEJO</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBChB (Ife), MD, FMCP, Fellow, Academy of Medicine
                            Specialties (FAMedS), Fellow, American Academy of
                            Neurology (FAAN)
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Consultant & Head of Department
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Neurology; Movement Disorders
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={adewale} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Prof. David Adewale OKE</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            BSc, MBBS (Lagos), FMCP, Dip (Cardio)
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Consultant & Provost, College of Medicine,
                            University of Lagos
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Cardiology
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={ajuluchukwu} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Prof. Janet Ngozi AJULUCHUKWU</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, MPH, FMCPH,FWACP
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Consultant & Head of Unit
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Cardiology
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={ojini} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Prof. Francis I. OJINI</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS (Ibadan), MSc Clinical Neuroscience (University
                            College London), FMCP
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Consultant & Head of Unit
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Neurology
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={mbakwem} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Prof. Amam C. MBAKWEM</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, FWACP, FMEd, FACC, FESC, FNCS
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Consultant & Head of Unit
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Cardiology
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Prof. Olufunmilayo LESI</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBCHB, MD, MBA, FMCP, FWACP
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Consultant
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Gastroenterology
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={fasanmade} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Prof. Olufemi FASANMADE</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, MPA, FWACP, FACE, FNSEM, FAMedS
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Consultant & Head of Unit
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Endocrinology, Diabetes and Metabolism
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={amira} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Prof. Christiana Oluwatoyin AMIRA</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS (Ibadan), MSc (Wits), FWACP
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Consultant & Head of Unit
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Nephrology
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={olayemi} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Prof. Sunday OLAYEMI</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, MSc, MPH, FWACP
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Consultant
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Emergency Medicine; Nephrology; Clinical
                            Pharmacology & Therapeutics
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={adelowo} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Prof. Olufemi Oladipo ADELOWO</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MD, FMCP, FWACP, FRCP Edin, FRCP, FAMedS, MACR
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Consultant & Head of Unit
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Rheumatology
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Monica Omolara MABAYOJE</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS (Lagos), FMCP
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Consultant & Head of Unit
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Nephrologist
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={ayanlowo} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Olusola Olabisi AYANLOWO</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, MSc (Clinical Dermatology), FWACP
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Consultant & Head of Unit
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Dermatology and Genitourinary Medicine
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={ozoh} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Obianuju Beatrice OZOH</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, MSc (Global Health), FMCP, ATSF
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Consultant & Head of Unit
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Pulmonology
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={ojo} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Oluwadamilola O. OJO</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS (Ilorin), FMCP
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Consultant
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Neurology, Movement disorders
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={odeniyi} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Ifedayo Adeola ODENIYI</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, MWACP, MD, FMCP, FACE
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Consultant
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Endocrinology, Diabetes & Metabolism
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={bello} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Babawale T. Bello</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBChB (Ife), MD, MRCP(UK), FMCP
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Consultant
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Nephrology
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={akinkugbe} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Ayesha Omolara AKINKUGBE</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBCh, FWACP, IFAAD
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Consultant
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Dermatology & Genitourinary Medicine
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={korede} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Olagoke Korede ALE</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, MPH, FMCP
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Consultant
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Cardiology
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={braimoh} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Rotimi Williams BRAIMOH</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, FWACP
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Consultant
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Nephrology
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={amadi} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Casmir Ezenwa AMADI</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            B.MedSci, MBBS, MSc, FMCP, FESC
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Consultant
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Cardiology
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={adewunmi} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Adebowale ADEWUNMI</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, MBA, FMCP
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Consultant
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Nephrology
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={akinsanya} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Akinsanya D. OLUSEGUN-JOSEPH</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, FWACP
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Consultant
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Cardiology
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={adegoke} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Oluseyi ADEGOKE</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, FMCP
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Consultant
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Cardiology
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={oyeleke} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Ganiyat Kikelomo OYELEKE</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, FMCP
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Consultant Physician
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Gastroentrology
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={kalejaiye} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Olufunto KALEJAIYE</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBCHB, FMCP
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Consultant & Head of Unit
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Haematology & Oncology
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={odeghe} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Emuobor A. ODEGHE</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MB.BS, FWACP
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Consultant & Head of Unit
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Gastroenterology
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={dania} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Michelle Grace DANIA</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, FMCP
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Consultant
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Pulmonology
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={olopade} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Oluwarotimi Bolaji OLOPADE</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, FMCP, FACE, Dip. Endocrinology (USW)
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Consultant
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Endocrinology, Diabetes & Metabolism
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={otrofanowei} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Erere OTROFANOWEI</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, FMCP
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Consultant
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Dermatology & Genitourinary Medicine
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={akase} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Iorhen E. AKASE</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, MSc (Immunology), FWACP
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Consultant & Head of Unit
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Infectious Diseases & Immunology
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={agabi} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Paul Osigwe AGABI</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, MWACP, FMCP
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Consultant
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Neurology; Electrodiagnostic Neurology
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={kushimo} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Oyewole A. KUSHIMO</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, MWACP, FMCP
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Consultant
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Cardiology
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Uyiekpen IMA-EDOMWONYI</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, FMCP
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Consultant
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Rheumatology
                          </p>
                        </div>
                      </div>
                      <h2 className='blog-one__title' style={{ marginTop: 50 }}>
                        Subspeciality Units and List of Consultants
                      </h2>
                      <Table fontSize='md' striped>
                        <thead>
                          <tr>
                            <th>Sub-Speciality</th>
                            <th>Consultants</th>
                          </tr>
                        </thead>
                        <tbody>{subRows}</tbody>
                      </Table>
                    </React.Fragment>
                  </div>
                  <div label='Contact'>
                    <React.Fragment>
                      <h2 className='blog-one__title'>Departmental Email</h2>
                      <p className='course-details__tab-text'>
                        <a href='mailto:internalmed@luth.gov.ng'>                        
                        internalmed@luth.gov.ng
                      </a>
                      </p>
                    </React.Fragment>
                  </div>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  );
};

export default Medicine;
